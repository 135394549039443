<template>
  <div class="goods-img-content">
    <div class="pw-score-main">
      <el-table
        :data="data"
        style="width: 100%"
        height="100%"
        header-cell-class-name="pw-table-header"
      >
        <el-table-column
          prop="competition_name"
          label="竞赛名称"
          width="200"
          align="center"
        ></el-table-column>
        <el-table-column
          prop="username"
          label="工位号"
          width="100"
          align="center"
        ></el-table-column>
        <el-table-column
          prop=""
          label="答案(商品只展示前5件商品)"
          align="center"
        >
          <template slot-scope="scope">
            <div class="pw-store-banner">
              <template v-if="scope.row.answer">
                <div
                  class="goods-list"
                  v-for="(item, index) in scope.row.answer"
                >
                  <div class="goods-item" v-if="index < 5">
                    <router-link
                      :to="{
                        path: '/teacher/student/goods',
                        query: { id: item.goods_id },
                      }"
                      tag="a"
                      target="_blank"
                    >
                      <img :src="item.url" alt="查看商品详情" />
                    </router-link>
                  </div>
                </div>
              </template>
              <template v-else>
                <span>该学生未提交答案</span>
              </template>
            </div>
          </template>
        </el-table-column>
<!--        <el-table-column-->
<!--          prop=""-->
<!--          :label="`评分（分值最高为${-->
<!--            totalScore == null ? '0' : totalScore-->
<!--          }分）`"-->
<!--          width="200"-->
<!--          align="center"-->
<!--        >-->
          <el-table-column
              prop=""
              :label="`评分`"
              width="200"
              align="center"
          >
          <template slot-scope="scope">
            <div class="pf-act-content">
              <!-- <template v-if="isScoreList[scope.row.s_id]"> -->

              <el-input-number
                v-model="scope.row.score"
                controls-position="right"
                class="pw-input"
                :min="0"
                :max="totalScore"
              ></el-input-number>
              <!-- <el-tooltip content="确定之后，则无法修改分数" placement="top-start"> -->
              <el-button
                type="primary"
                size="small"
                class="pw-confirm-btn"
                @click="confirmScore(scope.row)"
                >确定</el-button
              >
              <!-- </el-tooltip> -->

              <!-- <template v-else>
                                <span>{{scope.row.score}}分</span>
                            </template> -->
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="100" align="center">
          <template slot-scope="scope">
            <router-link
              :to="{
                path: '/teacher/student/store',
                query: { cid: $route.query.id, sid: scope.row.s_id },
              }"
              tag="a"
              target="_blank"
              ><span>查看详情</span></router-link
            >
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="pw-page-pagination">
      <el-pagination
        background
        layout="prev, pager, next"
        :current-page.sync="currentPage"
        :page-size.sync="limit"
        @current-change="changePage"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import _ from "underscore";
export default {
  name: "GoodsImgScore",
  data() {
    return {
      currentPage: 1,
      total: 10,
      data: [],
      totalScore: 0,
      isScoreList: [],
      limit: 10,
    };
  },
  mounted() {
    this.getStuList()
  },
  methods: {
    getStuList() {
      let param = {
        page: this.currentPage,
        limit: this.limit,
        type: 4,
        competition_id: this.$route.query.id,
      };
      this.$http.axiosGetBy(
        this.$api.answerList,
        param,
        (res) => {
          if (res.code === 200) {
            this.data = res.data.Answer;
            this.totalScore = res.data.model_score;
            this.total = res.data.stu_total;

            _.each(this.data, (item) => {
              if (item.score === null) {
                this.isScoreList[item.s_id] = true;
              } else {
                this.isScoreList[item.s_id] = false;
              }
            });
          } else {
            this.data = [];
          }
        },
        (error) => {
          console.log(error);
          this.$common.axiosErrorMsg(error);
        }
      );
    },
    changePage(val) {
      this.currentPage = val;
      this.getStuList();
    },
    confirmScore(value) {
      let data = {
        competition_id: this.$route.query.id,
        result_type: 4,
        user_id: value.s_id,
        score: value.score,
      };
      this.$http.axiosPost(
        this.$api.pwScore,
        data,
        (res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.getStuList();
          } else {
            this.$message({
              type: "warning",
              message: res.msg,
            });
          }
        },
        (err) => {
          this.$common.axiosErrorMsg(err);
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.goods-img-content {
  padding: 30px 30px 0 30px;
  height: calc(100% - 30px);
  /deep/ .pw-table-header {
    background-color: #f3f4fa;
    color: #14141c;
    font-size: 14px;
  }
  .pw-store-banner {
    height: 80px;
    width: 100%;
    display: flex;
    padding: 0 20px;
    justify-content: center;
    align-items: center;
    .goods-list {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .goods-item {
        width: 80px;
        height: 80px;
        margin-right: 10px;
        cursor: pointer;
        border: 1px solid #d1d2db;
        &:hover {
          border: 1px solid #eee;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .pf-act-content {
    display: flex;
    justify-content: center;
    align-items: center;
    .pw-input {
      width: 100px;
      margin-right: 8px;
    }
    .pw-confirm-btn {
      background-color: #1222d8;
      border-color: #1222d8;
    }
  }
}
</style>